@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@mixin font($font-family, $file-name, $font-weight, $font-style, $font-stretch) {
  @font-face {
    font-family: $font-family;
    font-stretch: $font-stretch;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url('/assets/scss/fonts/EOT/' + $file-name + '.eot');
    src:
      url('/assets/scss/fonts/EOT/' + $file-name + '.eot?#iefix') format('embedded-opentype'),
      url('/assets/scss/fonts/WOFF/' + $file-name + '.woff') format('woff'),
      url('/assets/scss/fonts/WOFF2/' + $file-name + '.woff2') format('woff2'),
      url('/assets/scss/fonts/SVG/' + $file-name + '.svg') format('svg');
  }
}

@include font('Delivery', 'Delivery_W_Rg', normal, normal, normal);
@include font('Delivery-Bold', 'Delivery_W_Bd', bold, normal, normal);
@include font('Delivery-Bold-Italic', 'Delivery_W_BdIt', bold, italic, normal);
@include font('Delivery-Black-Condensed', 'Delivery_W_CdBlk', normal, normal, condensed);
@include font('Delivery-Light-Condensed', 'Delivery_W_CdLt', light, normal, condensed);
@include font('Delivery-Light', 'Delivery_W_Lt', light, normal, normal);
@include font('Delivery-Italic', 'Delivery_W_It', normal, italic, normal);
@include font('Delivery-Light-Italic', 'Delivery_W_LtIt', light, italic, normal);

$breakpoint-tablet: 76.8rem;
$font-20: 2rem;
$amber: #fc0;
$lightamber: #fff0b0;
$boxgrey: #f2f2f2;
$boxborder: #c3c3c0;
$statistics-open: #e70000;
$statistics-resolving: #02903e52;
$statistics-accepted: #eaa200;
$txtcolor: #4b4b4b; // grey-700
$grey: #656a70; // grey-500
$txtgrey: #919191; // grey-400
$grey-300: #a9a9a9; // grey-300
$lightgrey: #efefef; // grey-200
$lightergrey: #f8f8f8; // grey-100
$txt-disabled: #adadad;
$trans-grey: #a9a9a938;
$trans-light-grey: #a9a9a918;
$footergrey: #f1f1f1;
$border-grey: #dbd9d9;
$footercolor: #9292a5;
$white: #fff;
$red: #e70000;
$btn-red: #bd2130;
$background-red: #e700000f;
$green: #02903e;
$black: #000;
$lightblack: #00000029;
$scroll: #fcf0b0;
$status-close: #c3c3c0;
$status-close-border: #1bb1cc;
$status-open-border: #d405113b;
$status-accepted: #ffe580;
$status-accepted-border: #eaa200;
$status-resolving: #02903e52;
$status-resolving-border: #18b1cc;
$status-deactivate-border: #bdc1c5;
$status-deactivate: #f4eded;
$status-deactivate-color: #8f8c8c;
$boldfont: Delivery-Bold;
$font: Delivery;
$font-bc: Delivery-Black-Condensed;
$font-b: Delivery-Bold;
$box-shadow-grey: rgb(128, 128, 128);
$scrollbar-thumb: #29292973;
$scrollbar-thumb-hover: #292929f5;
$app-sel-bg: rgba(0, 0, 0, 0.7);
$border-radius: 4px;
$link: #00f;
$pink: #ff939c;
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
